export function filterObjectProperties<T extends object>(obj: T, acceptNullUndefinedEmpty?: boolean): Partial<T> {
  const result: Partial<T> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if ((value !== null && value !== undefined && value !== '') || acceptNullUndefinedEmpty) {
        result[key] = value;
      }
    }
  }

  return result;
}
