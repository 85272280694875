import { Component, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidebarComponent, SidebarContainerComponent, SidebarContentComponent, TopbarComponent } from '@af-web/ui';
import { MatAnchor } from '@angular/material/button';
import { NgIcon } from '@ng-icons/core';
import { AuthService } from '@af-web/auth';
import { MenuService } from '../../services/menu/menu.service';

@Component({
  selector: 'afw-main',
  standalone: true,
  imports: [CommonModule, TopbarComponent, RouterOutlet, SidebarContainerComponent, SidebarComponent, MatAnchor, RouterLink, NgIcon, SidebarContentComponent, NgOptimizedImage],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent {

  private authService: AuthService = inject(AuthService);
  protected menuService: MenuService = inject(MenuService);

  logout() {
    this.authService.logout();
  }
}
