import { inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './token-const';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private jwtHelperService: JwtHelperService = inject(JwtHelperService);
  private accessTokenKey: string = inject(TOKEN_KEY);
  private refreshTokenKey: string = inject(REFRESH_TOKEN_KEY);

  get token(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  set token(token: string) {
    localStorage.setItem(this.accessTokenKey, token);
  }

  get refreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  set refreshToken(refreshToken: string) {
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  removeTokens() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }

  isTokenValid(token = this.token): boolean {
    return !this.jwtHelperService.isTokenExpired(token!);
  }

  isTokenExpired(token = this.token): boolean {
    return this.jwtHelperService.isTokenExpired(token!);
  }
}
