import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'afw-auth',
  standalone: true,
  imports: [CommonModule, MatFormField, MatLabel, MatInput, MatButton, MatRipple, RouterLink, RouterOutlet],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {}
