const LUCRO_PRESUMIDO_CODE = 1;
const LUCRO_REAL_CODE = 2;

export class RegimeTributarioEnum {
  public static readonly LUCRO_PRESUMIDO = new RegimeTributarioEnum(LUCRO_PRESUMIDO_CODE, 'Lucro Presumido');
  public static readonly LUCRO_REAL = new RegimeTributarioEnum(LUCRO_REAL_CODE, 'Lucro Real');

  private constructor(
    private _code: number,
    private _descricao: string
  ) {
  }

  get code() {
    return this._code;
  }

  get descricao() {
    return this._descricao;
  }

  public static values(): RegimeTributarioEnum[] {
    return Object.values(RegimeTributarioEnum).filter((value) => value instanceof RegimeTributarioEnum);
  }

  public static findByCode(code: number) {
    switch (code) {
      case LUCRO_PRESUMIDO_CODE:
        return RegimeTributarioEnum.LUCRO_PRESUMIDO;
      case LUCRO_REAL_CODE:
        return RegimeTributarioEnum.LUCRO_REAL;
      default:
        throw Error('Invalid code');
    }
  }


}
