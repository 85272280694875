import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SwitchThemeService {
  document = inject(DOCUMENT);

  switchTheme() {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    if (darkThemeMq.matches) {
      this.document.body.classList.add('fv-dark');
    } else {
      this.document.body.classList.remove('fv-dark');
    }
  }

}
