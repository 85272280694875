import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

export interface JsonResponse<T> {
  result: T;
  message: string;
  messages: Array<string>;
}

export interface JsonResponseList<T> {
  result: Array<T>;
  message: string;
  messages: Array<string>;
}

export interface Page<T> {
  content: Array<T>;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
}

export interface JsonResponsePageable<T> {
  result: Page<T>;
  message: string;
  messages: Array<string>;
}

export function trateError(err: HttpErrorResponse): string | Array<string> {
  let message: string | Array<string> = 'Ocorreu um erro durante a ação. Por favor contate o suporte!';

  if (err.error) {
    if (err.error.messages) {
      message = err.error.messages as Array<string>;
    } else {
      message = err.error.message;
    }
  }
  return message;
}

export function trateErrorToastr(err: HttpErrorResponse, toastrService: ToastrService) {
  const message: string | Array<string> = trateError(err);
  if (message instanceof Array) {
    message.forEach(m => toastrService.error(m, 'Erro'));
    return;
  }
  toastrService.error(message, 'Erro');
}
