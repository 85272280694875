import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { StringUtils } from './stirng-util';

function timeDaysDifference(date1: Date, date2: Date) {
  const timeDifference = date2.getTime() - date1.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return {
    timeDifference,
    daysDifference
  };
}

export function differenceBetweenDates(date1: Date, date2: Date): {years: number, months: number, days: number} {
  const timeDaysDiff = timeDaysDifference(date1, date2);
  const daysDifference = timeDaysDiff.daysDifference;

  const years = Math.floor(daysDifference / 365);
  const months = Math.floor((daysDifference % 365) / 30);
  const days = daysDifference - (years * 365) - (months * 30);

  return {
    years, months, days
  };
}

export function differenceBetweenDatesInMonths(date1: Date, date2: Date) {
  const diffYears = date2.getFullYear() - date1.getFullYear();
  const diffMonths = date2.getMonth() - date1.getMonth();
  return diffYears * 12 + (diffMonths + 1);
}

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  private ngxMaskService: NgxMaskService = inject(NgxMaskService);
  private locale: string = inject(LOCALE_ID);

  transformToNgxMask(date: Date) {
    return this.ngxMaskService.applyMask(date.toLocaleDateString(this.locale), '00/00/0000');
  }

  onTypedDate(dateStr: string, formControlMaskName: string, formGroup: FormGroup) {
    if (StringUtils.isEmpty(dateStr)) {
      formGroup.get(formControlMaskName)?.setValue(undefined, { emitEvent: false });
      return;
    }

    const dateSplited = dateStr.split('/');
    const day = +dateSplited[0];
    const month = +dateSplited[1] - 1;
    const year = +dateSplited[2];

    const date = new Date(year, month, day, 0, 0, 0, 0);
    if (isFinite(date.getTime())) {
      formGroup.get(formControlMaskName)?.setValue(date, { emitEvent: false });
    }
  }


  onDateChange(dateChange: MatDatepickerInputEvent<Date>, formControlName: string, formGroup: FormGroup) {
    const date: Date = dateChange.value!;
    const result = this.transformToNgxMask(date);
    formGroup.get(formControlName)?.setValue(result);
  }
}
