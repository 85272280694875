import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators extends Validators {
  static equalTo(controlName: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      const controlToCompare = parent?.get(controlName);
      console.log(control.value !== controlToCompare?.value);
      return control.value !== controlToCompare?.value ?
        { equalTo: true } :
        null;
    };
  }

  static allEqual(...controlNames: string[]): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValues = controlNames.map(v => control?.get(v)?.value);
      return controlValues.every(v => v === controlValues[0]) ? null : { allEqual: true };
    };
  }

  static override email(control: AbstractControl) {
    return new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$').test(control.value) ?
      null :
      { email: true };
  }
}