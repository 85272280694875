<afw-sidebar-container>
  <afw-sidebar>
    <header>
      <img ngSrc="assets/ui/imgs/logo_455.svg" alt="Virtue Dynamics logo" height="64" width="64">
    </header>

    <nav>
      @for (menu of menuService.menus; track menu.id) {
        <a [routerLink]="menu.routerLink">
          @if (menu.menuIcon) {
            <ng-icon [name]="menu.menuIcon" size="20" />
          }
          {{menu.label}}
        </a>
      }
    </nav>

    <footer>
      <button (click)="logout()">
        <ng-icon name="lucideLogOut" /> Sair
      </button>
    </footer>
  </afw-sidebar>

  <afw-sidebar-content>
    <afw-topbar />
    <main>
      <router-outlet />
    </main>
  </afw-sidebar-content>
</afw-sidebar-container>