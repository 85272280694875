export class StringUtils {
  public static addZerosBefore(value: string) {
    if (value) {
      const maxZeros = 8;
      const zerosToAdd = maxZeros - value.length;
      if (zerosToAdd > 0) {
        value = '0'.repeat(zerosToAdd) + value;
      }
    }
    return value;
  }

  public static isEmpty(str?: string | null): boolean {
    return (!str || str!.length == 0);
  }

}
