import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from '../services/session/session.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const sessionService: SessionService = inject(SessionService);
  const router: Router = inject(Router);
  const authenticated: boolean = sessionService.isAuthenticated();
  if (!authenticated) {
    router.navigate(['..', 'auth']).then();
  }
  return authenticated;
};

export const noAuthGuard: CanActivateFn = (route, state) => {
  const sessionService: SessionService = inject(SessionService);
  const router: Router = inject(Router);
  const authenticated: boolean = sessionService.isAuthenticated();
  if (authenticated) {
    router.navigate(['..']).then();
  }
  return !authenticated;
};
