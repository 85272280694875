import { Route } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { noAuthGuard } from './guards/auth.guard';

export const authRoutes: Route[] = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [
      noAuthGuard
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./login/login.component').then(c => c.LoginComponent)
      },
      {
        path: 'configure-psw/:token',
        loadComponent: () => import('./configure-psw/configure-psw.component').then(c => c.ConfigurePswComponent)
      },
      {
        path: 'reset-psw',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadComponent: () => import('./reset-psw/afw-reset-psw.component').then(c => c.AfwResetPswComponent),
          },
          {
            path: 'success',
            loadComponent: () => import('./reset-psw/afw-reset-psw-success/afw-reset-psw-success.component').then(c => c.AfwResetPswSuccessComponent)
          },
          {
            path: ':token',
            loadComponent: () => import('./reset-psw/afw-reset-psw-form/afw-reset-psw-form.component').then(c => c.AfwResetPswFormComponent)
          }
        ]
      }
    ]
  }
];
