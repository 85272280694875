import { Injectable } from '@angular/core';
import { LoginModel } from '../../models/login/login.model';
import { AuthModel } from '../../models/auth/auth.model';
import { Observable } from 'rxjs';
import { PasswordModel } from '../../models/password/password.model';

@Injectable()
export abstract class AuthService {

  abstract login(login: LoginModel): Observable<boolean>;

  abstract logout(): void;

  protected abstract populateSession(auth: AuthModel): void;

  abstract configurePassword(passwordModel: PasswordModel, token: string): Observable<any>;
}
