import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class IconResgistry extends MatIconRegistry {

  private icons = [
    {
      name: 'id_card',
      path: 'assets/ui/icons/id_card.svg'
    }
  ];

  constructor(_httpClient: HttpClient, _sanitizer: DomSanitizer,
              @Optional() @Inject(DOCUMENT) document: any, _errorHandler: ErrorHandler) {
    super(_httpClient, _sanitizer, document, _errorHandler);
    this.icons.forEach(
      icon => this.addSvgIcon(icon.name, _sanitizer.bypassSecurityTrustResourceUrl(icon.path))
    );
  }

}
