import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDot',
  standalone: true,
})
export class RemoveDotPipe implements PipeTransform {
  transform(value: string | null, ...args: unknown[]): unknown {
    if (value) {
      return value.replace('.', '');
    }
    return value;
  }
}
