import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TokenService } from '../token/token.service';
import { UserModel } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private tokenService: TokenService = inject(TokenService);

  isAuthenticated() {
    return this.tokenService.isTokenValid() || this.tokenService.isTokenValid(this.tokenService.refreshToken);
  }

  private _userState: WritableSignal<UserModel> = signal({} as UserModel);

  get userState(): UserModel {
    return this._userState();
  }

  set userState(user: UserModel) {
    this._userState.set(user);
  }
}
