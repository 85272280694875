const PF_CODE = 1;
const PJ_CODE = 2;

export class TipoPessoa {
  public static readonly PF = new TipoPessoa(PF_CODE, 'PF', 'Pessoa Física', '000.000.000-00', 'CPF');
  public static readonly PJ = new TipoPessoa(PJ_CODE, 'PJ', 'Pessoa Jurídica', '00.000.000/0000-00', 'CNPJ');

  private constructor(
    private _code: number,
    private _acronym: string,
    private _descricao: string,
    private _mask: string,
    private _document: string
  ) {
  }

  get code() {
    return this._code;
  }

  get acronym() {
    return this._acronym;
  }

  get descricao() {
    return this._descricao;
  }

  get mask() {
    return this._mask;
  }

  get document() {
    return this._document;
  }

  public static findByCode(code: number) {
    switch (code) {
      case PF_CODE:
        return TipoPessoa.PF;
      case PJ_CODE:
        return TipoPessoa.PJ;
      default:
        throw Error('Invalid code');
    }
  }

  public static maskAndLabel(code: number): { label: string, mask: string } {
    const tipoPessoa = TipoPessoa.findByCode(code);
    if (tipoPessoa) {
      return {
        label: tipoPessoa.document,
        mask: tipoPessoa.mask
      };
    }
    throw Error('Could not get mask for the informed person type!');
  }

  public static maskOnly(code: number): string {
    const tipoPessoa = TipoPessoa.findByCode(code);
    if (tipoPessoa) {
      return tipoPessoa.mask;
    }
    throw Error('Could not get mask for the informed person type!');
  }


}
