export class UFEnum {
  public static readonly AC = new UFEnum(1, 'Acre', 'AC', '00.000.000/000-00');
  public static readonly AL = new UFEnum(2, 'Alagoas', 'AL', '000000000');
  public static readonly AP = new UFEnum(3, 'Amapá', 'AP', '000000000');
  public static readonly AM = new UFEnum(4, 'Amazonas', 'AM', '00.000.000-0');
  public static readonly BA = new UFEnum(5, 'Bahia', 'BA', '0000000-00');
  public static readonly CE = new UFEnum(6, 'Ceará', 'CE', '00000000-0');
  public static readonly DF = new UFEnum(7, 'Distrito Federal', 'DF', '00000000000-00');
  public static readonly ES = new UFEnum(8, 'Espírito Santo', 'ES', '00000000-0');
  public static readonly GO = new UFEnum(9, 'Goiás', 'GO', '00.000.000-0');
  public static readonly MA = new UFEnum(10, 'Maranhão', 'MA', '00000000-0');
  public static readonly MT = new UFEnum(11, 'Mato Grosso', 'MT', '0000000000-0');
  public static readonly MS = new UFEnum(12, 'Mato Grosso do Sul', 'MS', '00000000-0');
  public static readonly MG = new UFEnum(13, 'Minas Gerais', 'MG', '000.000.000/0000');
  public static readonly PA = new UFEnum(14, 'Pará', 'PA', '00-000000-0');
  public static readonly PB = new UFEnum(15, 'Paraíba', 'PB', '00000000-0');
  public static readonly PR = new UFEnum(16, 'Paraná', 'PR', '000.00000-00');
  public static readonly PE = new UFEnum(17, 'Pernambuco', 'PE', '0000000-00');
  public static readonly PI = new UFEnum(18, 'Piauí', 'PI', '00000000-0');
  public static readonly RJ = new UFEnum(10, 'Rio de Janeiro', 'RJ', '00.000.00-0');
  public static readonly RN = new UFEnum(20, 'Rio Grande do Norte', 'RN', '00.000.000-0');
  public static readonly RS = new UFEnum(21, 'Rio Grande do Sul', 'RS', '000/0000000');
  public static readonly RO = new UFEnum(22, 'Rondônia', 'RO', '0000000000000-0');
  public static readonly RR = new UFEnum(23, 'Roraima', 'RR', '00000000-0');
  public static readonly SC = new UFEnum(24, 'Santa Catarina', 'SC', '000.000.000');
  public static readonly SP = new UFEnum(25, 'São Paulo', 'SP', '000.000.000.000');
  public static readonly SE = new UFEnum(26, 'Sergipe', 'SE', '00000000-0');
  public static readonly TO = new UFEnum(27, 'Tocantins', 'TO', ['0000000000-0', '00000000-0']);

  private constructor(
    private _id: number,
    private _name: string,
    private _sigla: string,
    private _ieMask: string[] | string
  ) {
  }

  get id(): number {
    return this._id;
  }

  get sigla(): string {
    return this._sigla;
  }

  get name(): string {
    return this._name;
  }

  get ieMask(): string[] | string {
    return this._ieMask;
  }

  public static values(): UFEnum[] {
    return Object.values(UFEnum).filter((value) => value instanceof UFEnum);
  }

  public static findBySigla(sigla: string): UFEnum | undefined {
    for (const key of Object.keys(UFEnum)  as Array<keyof typeof UFEnum>) {
      if (typeof UFEnum[key] !== 'function' && (UFEnum[key] as UFEnum).sigla === sigla) {
        return UFEnum[key] as UFEnum;
      }
    }
    return undefined;
  }

  static maskOnly(sigla: string): string {
    const uf = UFEnum.findBySigla(sigla);
    if (uf) {
      const ieMask = uf.ieMask;
      if (Array.isArray(ieMask)) {
        let mask = ''
        ieMask.forEach((v, i) => {
          if (i != ieMask.length - 1) {
            mask += `${v}||`
          } else {
            mask += v;
          }
        });
        return mask;
      } else if (typeof uf.ieMask === 'string') {
        return uf.ieMask;
      }
    }
    return '';
  }

}